<template>
  <div class="app" @mousemove="mousemove">
    <div
      ref="cursor"
      :class="['app__cursor', { app__cursor_filter: isHoverFilter }]"
    >
      <div class="app__cursor-image" ref="cursorImage">
        <video
          v-for="(item, index) in filters"
          :key="index"
          ref="video"
          v-show="activeVideoId === item.value"
          class="app__cursor-video"
          width="100%"
          height="100%"
          muted="muted"
          :name="item.value"
        >
          <source :src="item.video" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <Header />
    <div class="app__content content">
      <RouterView
        @hoverItemFilter="hoverItemFilter"
        @leaveItemFilter="leaveItemFilter"
        @loadCategory="loadCategory"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import { ref } from "vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    const cursor = ref();
    const isHoverFilter = ref(false);
    const cursorImage = ref();

    const mousemove = (event) => {
      setTimeout(() => {
        cursor.value.style.left = `${
          event.clientX - (isHoverFilter.value ? 132 : 30)
        }px`;
        cursor.value.style.top = `${
          event.clientY - (isHoverFilter.value ? 132 : 30)
        }px`;
      }, 100);
    };

    const video = ref();

    const activeVideo = ref(null);
    const activeVideoId = ref(null);
    const hoverItemFilter = async (value) => {
      // console.log(
      //   filters.value.find((item) => item.value === value.value).video
      // );
      if (!filters.value.find((item) => item.value === value.value).video)
        return;
      activeVideoId.value = value.value;
      if (document.querySelector(`video[name='${value.value}']`)) {
        document.querySelector(`video[name='${value.value}']`).play();
      }
      isHoverFilter.value = true;
    };

    const listVideos = ref([
      { src: require("@/assets/video/mobile.mp4"), id: "mobile" },
      { src: require("@/assets/video/branding.mp4"), id: "branding" },
    ]);

    const leaveItemFilter = () => {
      if (document.querySelector(`video[name='${activeVideoId.value}']`)) {
        document.querySelector(`video[name='${activeVideoId.value}']`).pause();
      }
      activeVideoId.value = null;
      isHoverFilter.value = false;
      activeVideo.value = null;
    };

    const filters = ref(null);
    const loadCategory = (filterList) => {
      filters.value = filterList;
    };

    return {
      hoverItemFilter,
      mousemove,
      cursor,
      isHoverFilter,
      leaveItemFilter,
      cursorImage,
      activeVideo,
      video,
      listVideos,
      activeVideoId,
      loadCategory,
      filters,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/fonts/stylesheet.css";
@import "normalize.css";
@import "@/style/base.scss";
@import "perfect-scrollbar/css/perfect-scrollbar.css";

body {
  scroll-behavior: smooth;
}

.app {
  font-family: Gilroy;
  min-height: 100vh;
  padding-top: 120px;
  overflow-x: hidden;
  position: relative;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   height: 91px;
  //   background-color: white;
  //   z-index: 1;
  //   position: fixed;

  //   @include at("m") {
  //     height: 124px;
  //   }
  // }

  & > * {
    box-sizing: border-box;
    min-width: 0;
  }
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    z-index: 2;
    max-width: 100%;
  }

  &__cursor-video {
    width: 100%;
    height: 100%;
  }

  &__cursor {
    position: fixed;
    transform: scale(1);
    transition: top 0s, left 0s, transform 0.3s linear;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    border-top-right-radius: 0px;
    border: 1px solid #c9c9c9;
    pointer-events: none;
    overflow: hidden;
    z-index: 2;
    display: none;

    @include at("l") {
      display: block;
    }

    &_filter {
      transition: width 0.3s, height 0.3s;
      border-width: 0.2px;
      width: 264px;
      height: 264px;

      .app__cursor-image {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
    &-image {
      background-size: contain;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}

.preview-card__container-img > div {
  display: flex;
  align-items: self-start;
  //height: 100vh !important;
}
</style>

<template>
  <div
    ref="elCard"
    :class="[
      'preview-card',
      { 'preview-card_inWindow': inWindow && windowWidth > 1600 },
      { 'preview-card_first': indexCard === 0 },
    ]"
  >
    <!-- slides-per-view="2" -->
    <div class="thumbnail-container" style="width: 100%" ref="thumbnailContainer">
      <Swiper
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :class="[
          '',
          'preview-card__wrapper',
          { 'preview-card__wrapper_inWindow': inWindow && windowWidth > 1600 },
        ]"
        :breakpoints="swiperOptions.breakpoints"
        :speed="swiperSpeed || 0"
        :loop="true"
      >
        <div
          v-show="windowWidth > 1024"
          class="preview-card__button preview-card__button_left"
          @mouseover="startAnimationPrev"
          @mouseleave="finishAnimationPrev"
        />
        <div
          v-show="windowWidth > 1024"
          class="preview-card__button preview-card__button_right"
          @mouseover="startAnimationNext"
          @mouseleave="finishAnimationNext"
        />
        <SwiperSlide
          class="preview-card__slide"
          v-for="(img, index) in card.previews"
          :key="index"
        >
<!--          <ParallaxImage-->
<!--              :elem-y="containerTop"-->
<!--              :image-url="img"-->
<!--              :speed="50"-->
<!--          />-->
          <div
            :class="[
              'jarallax',
              'preview-card__container-img',
              { 'preview-card__container-img_show': isShowImg },
            ]"
          >
            <img
              data-depth="0.20"
              class="preview-card__img jarallax-img"
              :src="`https://viento.ru${img}`"
            />
            <!-- https://avatarko.ru/img/kartinka/2/zhivotnye_kot_prikol_ochki_1637.jpg -->
            <!-- <ScrollParallax :speed="0.15" class="preview-card__paralax">
          </ScrollParallax> -->
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="preview-card__footer">
      <span class="preview-card__title">{{ card.title }}</span>
      <span class="preview-card__description"> &nbsp;{{ card.desc }}</span>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
// import ScrollParallax from "vue3-parallax/src/components/ScrollParallax.vue";
import "swiper/css";
// import { VuePrlxDirective } from "vue-prlx";
// Vue.directive("prlx", VuePrlxDirective);
// import Parallax from "parallax-js";
import { jarallax } from "jarallax";
// import ParallaxImage from "@/components/ParallaxImage.vue";
// jarallaxVideo();
// import simpleParallax from "simple-parallax-js";

export default {
  name: "PreviewCard",
  components: {
    // ParallaxImage,
    Swiper,
    SwiperSlide,
    // ScrollParallax,
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
    indexCard: Number,
  },
  // data() {
  //   return {
  //     containerTop: 0,
  //   }
  // },
  // mounted() {
  //   this.containerTop = this.$refs.thumbnailContainer.getBoundingClientRect().top
  //   window.addEventListener("scroll", () => this.containerTop = this.$refs.thumbnailContainer.getBoundingClientRect().top);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", () => this.containerTop = this.$refs.thumbnailContainer.getBoundingClientRect().top);
  // },
  setup() {
    const swiperCard = ref(null);
    const swiperSpeed = ref(400);

    const prevTimeinterval = ref(null);
    const nextTimeinterval = ref(null);

    // const paralaxEffect = ref(0);

    const onSwiper = (swiper) => {
      swiperCard.value = swiper;
    };

    // const thumbnailContainerTop = ref('')

    // const getThumbnailContainerTop = computed(() => thumbnailContainerTop.getBoundingClientRect().top)
    const startAnimationPrev = async () => {
      swiperCard.value.slidePrev(swiperSpeed.value);
      prevTimeinterval.value = setInterval(() => {
        swiperCard.value.slidePrev(swiperSpeed.value);
      }, swiperSpeed.value * 3);
    };

    const finishAnimationPrev = () => {
      clearInterval(prevTimeinterval.value);
    };

    const startAnimationNext = async () => {
      swiperCard.value.slideNext(swiperSpeed.value);
      nextTimeinterval.value = setInterval(() => {
        swiperCard.value.slideNext(swiperSpeed.value);
      }, swiperSpeed.value * 3);
    };

    const finishAnimationNext = () => {
      clearInterval(nextTimeinterval.value);
    };

    const inWindow = ref(false);
    const elCard = ref();

    let observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(function () {
          // inWindow.value = entry.isIntersecting;
          inWindow.value = false;
        });
      },
      {
        threshold: 0.6,
      }
    );

    const paralax = ref(null);

    const debounse = ref(null);

    const changeParamsparalaForHiddenSlide = () => {
      if (!inWindow?.value) return;
      clearTimeout(debounse.value);
      debounse.value = setTimeout(() => {
        const transform = elCard.value.querySelector(".swiper-slide-active img")
          .style.transform;

        elCard.value.querySelectorAll(".thumbnail").forEach((element) => {
          element.style.transform = transform;
        });
        // console.log(
        // );
      }, 100);
    };

    const initParalax = () => {
      if (paralax.value) paralax.value.destroy();
      new jarallax(elCard.value.querySelectorAll(".jarallax"), {
        speed: 1.2,
      });
    };

    const isShowImg = ref(false);

    onMounted(() => {
      // window.addEventListener("scroll", () => this.handleScroll());
      addEventListener("scroll", () => {
        changeParamsparalaForHiddenSlide()
        if (windowWidth.value < 833) fixTransformForImages()
      });
      initParalax();
      setTimeout(() => {
        if (elCard.value) observer.observe(elCard.value);
      }, 1000);
      isShowImg.value = true;
    });

    const swiperOptions = ref({
      breakpoints: {
        320: {
          slidesPerView: 1.3,
          spaceBetween: 12,
        },
        375: {
          slidesPerView: 1.2,
          spaceBetween: 12,
        },
        833: {
          slidesPerView: 2.3,
          spaceBetween: 24,
        },
        1111: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    });

    const windowWidth = ref(0);

    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    const fixTransformForImages = () => {
      const cards = elCard.value.querySelectorAll('.preview-card__img')

      cards.forEach(card2 => {
        const transform = card2.style.transform
        const filteredTransform = transform.split('px')[1]?.replace(/,/g, '').trim()
        const height = card2.style.height
        const filteredHeight = height.split('px')[0]
        console.log('filteredHalfHeight', +filteredHeight / 2.5)
        console.log('Math.abs(+filteredTransform)', Math.abs(+filteredTransform))

        if (+filteredTransform > 0 || +filteredHeight / 2.5 < Math.abs(+filteredTransform)) {

          card2.style.transform = `translate3d(0px, -${elCard.value.getBoundingClientRect().y}px, 0px)`;
        }
      })
    }

    const onSlideChange = () => {
      if (windowWidth.value < 833) fixTransformForImages()
    }

    return {
      onSwiper,
      swiperCard,
      startAnimationPrev,
      finishAnimationPrev,
      swiperSpeed,
      startAnimationNext,
      finishAnimationNext,
      inWindow,
      elCard,
      isShowImg,
      swiperOptions,
      windowWidth,
      initParalax,
      onSlideChange,
      // getThumbnailContainerTop,
      // thumbnail,
    };
  },
};
</script>

<style lang="scss" scoped>
.preview-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    .preview-card__container-img {
      @include at("l") {
        border-radius: 0;
      }
    }

    .preview-card__img {
      border-radius: 0;

      // transition: transform 0.8s;

      // @include at("l") {
      //   transform: scale(1);
      // }
    }
  }

  @include at("l") {
    align-items: center;
  }

  &__paralax {
    height: 100%;
  }

  ::v-deep .swiper-wrapper {
    transition-timing-function: linear;
  }

  &__wrapper {
    margin: 0;
    width: 100%;
    border-radius: 24px;
    position: relative;
    // margin-bottom: 16px;
    height: 399px;
    transition: height 1s;

    .preview-card_first & {
      border-top-right-radius: 0 !important;
    }

    @include at("m") {
      height: 597px;
    }

    @include at("l") {
      height: 420px;
      margin-bottom: 32px;
    }

    @include at("lg") {
      height: 520px;
      margin-bottom: 32px;
    }

    @include at("hd") {
      height: 820px;
      margin-bottom: 32px;
    }

    &_inWindow {
      height: 720px;
      transition: height 1s;
    }
  }

  &__container-img {
    transition: top 0.4s linear;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 150px;
    overflow: hidden;
    border-radius: 24px;
    min-width: calc(100% + 2px);

    .preview-card_first & {
      border-top-right-radius: 0 !important;
    }

    &_show {
      top: 0;

      .preview-card__img {
        position: relative;
        // top: -15%;
        // height: 130%;
      }
    }
  }

  &__slide {
    @include between("m", "l") {
      width: 60%;
    }
    @include between("l", "hd") {
      width: 40%;
    }
  }

  &__img {
    // height: 389px;

    width: 100%;
    // transform: scale(1.1);
    // transition: transform 0.8s;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;

    // @include at(374px) {
    //   object-fit: contain !important;
    // }

    // object-fit: contain !important;
    // @include between("", 374px) {
    // }

    // @include at("m") {
    //   height: 597px;
    // }

    // @include at("l") {
    //   transform: scale(1);
    // }

    // @include at("hd") {
    //   height: 820px;
    // }
  }

  &__description,
  &__title {
    font-size: 20px;
    line-height: 25px;

    @include at("m") {
      font-size: 32px;
      line-height: 40px;
    }

    @include at("l") {
      font-size: 20px;
      line-height: 28px;
    }

    @include at("hd") {
      font-size: 36px;
      line-height: 45px;
    }
  }

  &__title {
    font-weight: bold;
  }

  &__button {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    z-index: 1;

    &_right {
      right: 0;
      &:hover {
        cursor: url("@/assets/next.svg"), pointer;
      }
    }

    &_left {
      &:hover {
        cursor: url("@/assets/prev.svg"), pointer;
      }
    }
  }

  .thumbnail {
    width: 100%;
  }

  .thumbnail-container {
    overflow: hidden;
    margin-bottom: 16px;
  }

  .jarallax {
    // position: relative;
    z-index: 0;
  }
  .jarallax-img {
    margin-top: 0 !important;
    position: absolute;
    // object-fit: contain !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh !important;
    z-index: -1;
    border-radius: 24px;
  }
}
</style>

<template>
  <div :class="`title title_color-${color} title_size-${size}`">
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Title",
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "black",
    },
    size: {
      type: String,
      default: "lg",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.title {
  &_color {
    &-white {
      color: white;
    }

    &-black {
      color: #000000;
    }
  }

  &_size {
    &-lg {
      font-size: 32px;
      font-weight: 900;

      @include at("m") {
        font-size: 48px;
      }
    }
  }
}
</style>

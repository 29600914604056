<template>
  <InlineSvg :src="require(`./assets/${type}.svg`)" />
</template>

<script>
import { computed } from "vue";
import InlineSvg from "vue-inline-svg";

export default {
  name: "Icon",
  components: {
    InlineSvg,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const component = computed(() => {
      return props.type ? props.type : "div";
    });

    return {
      component,
    };
  },
};
</script>

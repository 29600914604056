<template>
  <div class="footer">
    <Title class="footer__title" :text="$t('titleForm')" color="white" />
    <FooterForm class="footer__form" />

    <div class="footer__about">
      <div>
        <a class="footer__link footer__phone"
href="tel:74956496838"
          >+7 (495) 649 68 38</a
        >
        <a href="mailto:contact@viento.ru"
class="footer__link footer__email"
          >info@viento.ru</a
        >
      </div>
      <div class="footer__socials">
        <a class="footer__social" href="">
          <Icon type="be" />
        </a>
        <!--<a class="footer__social footer__social_inst" href="">
          <Icon type="instagram" />
        </a>
        <a class="footer__social" href="">
          <Icon type="facebook" />
        </a>-->
      </div>
    </div>
  </div>
</template>

<script>
import FooterForm from "@/components/Forms/FooterForm.vue";
import Icon from "@/components/Uikit/Icon/Icon.vue";
export default {
  name: "Footer",
  components: {
    FooterForm,
    Icon,
  },
  props: {},
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #131313;
  padding: 40px 24px;

  @include at("m") {
    padding: 56px 40px;
  }

  @include at("hd") {
    padding: 112px 262px;
  }

  &__form {
    margin-bottom: 64px;

    @include at("l") {
      margin-bottom: 155px;
    }
  }

  &__title {
    margin-bottom: 32px;

    @include at("m") {
      margin-bottom: 40px;
    }

    @include at("hd") {
      margin-bottom: 56px;
    }
  }

  &__about {
    color: #ffffff;
    display: flex;
    justify-content: space-between;

    @include below("m") {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__email,
  &__phone {
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    border: none;
    text-decoration: none;
    margin-right: 64px;
    position: relative;

    @include at("l") {
      font-size: 32px;
      line-height: 38px;
    }

    @include below("m") {
      margin-right: 0;
      margin-bottom: 24px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  &__phone {
    transition: color 0.3s;
    &:hover {
      transition: color 0.3s;
      color: #f15a22;
    }
  }

  &__email {
    transition: color 0.3s;

    @include below("m") {
      margin-bottom: 44px;
    }

    &:hover {
      &:before {
        transition: background 0.3s;
        background: #f15a22;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #7d7d7d;
      bottom: -4px;
      transition: background 0.3s;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
  }

  &__social {
    ::v-deep path {
      transition: fill 0.3s;
    }
    &:hover {
      ::v-deep path {
        transition: fill 0.3s;
        fill: #0057ff;
      }
    }
    &:not(:last-child) {
      margin-right: 64px;
    }

    &_inst {
      ::v-deep path {
        fill: url(#inst-shape-gradient) #fff !important;
      }

      ::v-deep stop {
        transition: 0.3s;
      }
      &:hover {
        ::v-deep stop:first-child {
          stop-color: #d41981;
          transition: 0.3s;
        }
        ::v-deep stop:last-child {
          stop-color: #fed66d;
          transition: 0.3s;
        }
      }
    }
  }
}
</style>

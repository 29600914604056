<template>
  <div class="radio">
    <template v-for="(item, index) in items" :key="index">
      <label
        :required="required"
        :class="['radio__label', { radio__label_active: model === item.value }]"
      >
        {{ item.label }}
        <input
          class="radio__input"
          :name="group"
          type="radio"
          :value="item.value"
          @input="$emit('update:model', $event.target.value)"
        />
      </label>
    </template>
  </div>
</template>

<script>
// import { ref } from "@vue/runtime-core";

export default {
  name: "RadioInput",
  components: {},
  props: ["items", "label", "model", "group"],
  emits: ["update:model"],
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.radio {
  color: white;
  font-weight: 600;
  font-size: 16px;

  @include at("m") {
    font-weight: 600;
    font-size: 20px;
  }

  // height: 72px;

  &__input {
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__label {
    margin-bottom: 12px;
    padding: 0 31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff80;
    border-radius: 16px;
    height: 52px;
    background: transparent;
    transition: border-radius 0.3s, color 0.3s, background 0.3s,
      border-color 0.3s;

    @include at("m") {
      height: 72px;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:hover {
      border-color: white;
      transition: border-color 0.3s;
    }

    &_active {
      background: white;
      color: black;
      border-radius: 16px 0 16px 16px;
      transition: all 0.3s, color 0.3s, background 0.3s, border-radius 0.3s;
    }
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Title from "@/components/Uikit/Title.vue";
import Maska from "maska";
import { createI18n } from "vue3-i18n";
import ScrollParallax from "vue3-parallax/src/components/ScrollParallax.vue";

// const app = createApp(App);
// app.component('scroll-parallax', ScrollParallax);

import params from "@/i18/params";
import vClickOutside from "click-outside-vue3";
// import VueParallaxJs from "vue-parallax-js";

const i18n = createI18n({
  locale: "ru",
  messages: params,
});

const app = createApp(App);
app.component("Title", Title);
app.use(Maska);
app.use(router);
app.component("scroll-parallax", ScrollParallax);
app.use(vClickOutside);
app.use(i18n);
app.mount("#app");

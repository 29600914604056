<template>
  <div :class="`button button_type-${type}`">
    <span>{{ text }}</span>
    <Icon :type="icon" class="button__icon" />
  </div>
</template>

<script>
import Icon from "@/components/Uikit/Icon/Icon.vue";

export default {
  name: "Button",
  components: {
    Icon,
  },
  props: {
    text: String,
    type: {
      type: String,
      default: "normal",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.button {
  background-color: white;
  border-radius: 16px;
  border: #f15a22 2px solid;
  display: inline-flex;
  font-size: 20px;
  height: 72px;
  padding: 0px 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 500;

  @include below("m") {
    height: 48px;
    padding: 0px 18px;
  }

  &_type {
    &-transparent {
      background-color: transparent;
      color: white;
      transition: background-color 0.3s, color 0s, border-radius 0.3s;

      .button__icon {
        fill: white;

        ::v-deep path {
          transition: stroke 0.3s;
          stroke: white;
        }
      }
    }
  }

  &:hover {
    background-color: #f15a22;
    transition: background-color 0.3s, color 0s, border-radius 0.3s;
    color: white;
    border-radius: 16px 0 16px 16px;

    .button__icon {
      fill: white;

      ::v-deep path {
        transition: stroke 0.3s;
        stroke: white;
      }
    }
  }

  &__icon {
    margin-left: 15px;
  }
}
</style>

<template>
  <form class="footer-form">
    <div>
      <span class="footer-form__group-title">{{ $t("formRadioLabel") }}</span>
      <RadioInput
        :items="types"
        v-model:model="type"
        :required="false"
        group="types"
        class="footer-form__field footer-form__field_radio"
      />
    </div>
    <Input
      class="footer-form__field"
      v-model="name"
      :placeholder="$t('inputNamePlaceholder')"
    />
    <Input
      class="footer-form__phone"
      v-model="phone"
      :placeholder="$t('inputPhonePlaceholder')"
      mask="+7 (###) ### ##-##"
    />
    <Button
      class="footer-form__button"
      @click="sendForm"
      :text="$t('buttonFormText')"
      type="transparent"
      icon="tg"
    />
  </form>
</template>

<script>
import Input from "@/components/Uikit/Input.vue";
import RadioInput from "@/components/Uikit/RadioInput.vue";
import Button from "@/components/Uikit/Button.vue";
import axios from "axios";
import { useI18n } from "vue3-i18n";

import { ref, computed } from "vue";
const baseUrl =
  process.env.NODE_ENV === "development" ? "https://viento.ru/api" : "/api";

export default {
  name: "Footer-form",
  components: {
    Input,
    Button,
    RadioInput,
  },
  props: {},
  setup() {
    const name = ref("");
    const phone = ref("");

    const type = ref();

    const sendForm = () => {
      axios.post(`${baseUrl}/sendForm`, {
        name: name.value,
        type: type.value,
        phone: phone.value,
      });
    };
    const i18n = useI18n();
    const lang = computed(() => {
      return i18n.getLocale();
    });

    const types = computed(() => [
      {
        value: "Брендинг",
        label: lang.value === "ru" ? "Брендинг" : "Branding",
      },
      {
        value: "Веб-сайт",
        label: lang.value === "ru" ? "Веб-сайт" : "Website",
      },
      {
        value: "Мобильное приложение",
        label: lang.value === "ru" ? "Мобильное приложение" : "Mobile app",
      },
      {
        value: "Не определился",
        label: lang.value === "ru" ? "Не определился" : "He did not decide",
      },
    ]);

    return {
      name,
      sendForm,
      type,
      types,
      phone,
      lang,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-form {
  width: 100%;

  @include at("m") {
    width: 756px;
  }

  @include at("l") {
    width: 869px;
  }

  &__field {
    margin-bottom: 48px;
    width: 100%;

    &_radio {
      margin-bottom: 36px;
    }
  }

  &__button {
    @include below("m") {
      width: 100%;
      box-sizing: border-box;
    }
  }

  &__group-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #7d7d7d;
    margin-bottom: 16px;
    display: block;
  }

  &__phone {
    margin-bottom: 64px;
  }
}
</style>

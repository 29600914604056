<template>
  <div class="filter-container" ref="filterContainer">
    <div class="filter" v-if="filters.length > 0">
      <div
        v-for="(item, index) in filters"
        :key="index"
        :class="[
          'filter__item',
          { filter__item_active: active === item.value },
        ]"
        @click="selectFilter(item)"
        @mouseover="mouseover(item)"
        @mouseleave="leaveItemFilter(item)"
      >
        <Transition name="filter-item">
          <div
            v-if="isShowFilter"
            :style="`transition-delay: ${
              50 * index
            }ms; transition-property: transform;`"
          >
            {{ item.label }}
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
  name: "Filter",
  components: {},
  props: {
    filters: Array,
  },
  setup(props, { emit }) {
    const filterContainer = ref();
    const active = ref("all");
    const ps = ref(null);

    onMounted(() => {
      ps.value = new PerfectScrollbar(filterContainer.value, {
        suppressScrollY: true,
      });
      active.value = props.filters[0].value;
      emit("selectFilter", props.filters[0]);
    });

    const selectFilter = (value) => {
      active.value = value.value;
      emit("selectFilter", value);
    };

    const cursor = ref();
    const mouseover = (item) => {
      emit("hoverItemFilter", item);
    };

    const leaveItemFilter = (item) => {
      emit("leaveItemFilter", item);
    };

    const isShowFilter = ref(false);

    onMounted(() => {
      setTimeout(() => {
        isShowFilter.value = true;
      }, 0);
    });

    return {
      active,
      selectFilter,
      mouseover,
      cursor,
      leaveItemFilter,
      isShowFilter,
      filterContainer,
      ps,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  overflow: hidden;
  position: relative;
}

.filter {
  display: flex;

  @include below("l") {
    margin-right: -40px;
  }

  @include below("m") {
    margin-right: -24px;
  }

  &__item {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #c9c9c9;
    cursor: pointer;
    transition: color 0.3s;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 24px;

      @include at("m") {
        margin-right: 48px;
      }

      @include at("hd") {
        margin-right: 64px;
      }
    }

    &:last-child {
      @include at("s") {
        margin-right: 40px;
      }
    }

    @include at("m") {
      font-weight: 400;
      font-size: 48px;
      line-height: 61px;
    }

    @include at("m") {
      font-weight: 400;
      font-size: 38px;
      line-height: 61px;
    }

    @include at("l") {
      font-weight: 400;
      font-size: 28px;
      line-height: 61px;
    }

    @include at("lg") {
      font-weight: 400;
      font-size: 48px;
      line-height: 61px;
    }

    @include at("xl") {
      font-size: 64px;
      line-height: 77px;
    }

    &:hover {
      color: #f15a22;
      transition: color 0.3s;

      div {
        transition-delay: 0 !important;
      }
    }

    &_active {
      color: #000000;
      font-weight: 900;

      &:hover {
        color: inherit;
        transition: none;

        div {
          transition-delay: 0 !important;
        }
      }
    }
  }

  .filter-item-enter-active {
    transition: opacity 0.2s linear, transform 0.1s linear;
  }

  .filter-item-enter-from {
    transform: translateY(80px);
    opacity: 0;
  }
}
</style>

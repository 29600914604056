export default {
  en: {
    buttonHeaderName: "Contact",
    titleForm: "Do you have a project?",
    formRadioLabel: "I'm interested in..",
    inputNamePlaceholder: "your name",
    inputPhonePlaceholder: "your phone",
    buttonFormText: "Send an application",
  },
  ru: {
    buttonHeaderName: "Связаться",
    titleForm: "У Вас есть проект?",
    formRadioLabel: "Меня интересует..",
    inputNamePlaceholder: "Ваше имя",
    inputPhonePlaceholder: "Ваш телефон",
    buttonFormText: "отправить заявку",
  },
};

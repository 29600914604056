<template>
  <div>
    <input
      class="input"
      type="text"
      :value="modelValue"
      @input="handleInput"
      :placeholder="placeholder"
      v-maska="mask"
    />
  </div>
</template>

<script>
export default {
  name: "Input",
  components: {},
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    mask: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const handleInput = (event) => {
      emit("update:modelValue", event.target.value);
    };
    return {
      handleInput,
    };
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 20px;
  border: 0;
  border-bottom: 1px #898989 solid;
  padding: 16px 0;
  font-weight: 500;
  transition: color 0.2s, border 0.2s;

  &:hover {
    border-bottom: 1px #fff solid;
    transition: border 0.2s;

    &::placeholder {
      transition: color 0.2s;
      color: #fff;
    }
  }

  @include at("m") {
    font-size: 24px;
  }

  &:focus {
    outline: none;
  }
}
</style>

<template>
  <div class="home">
    <Filter
      v-if="filters.length"
      class="home__filter"
      v-model="activeFilter"
      @selectFilter="selectFilter"
      @hoverItemFilter="hoverItemFilter"
      @leaveItemFilter="leaveItemFilter"
      :filters="filters"
    />

    <div class="home__content" v-if="filteredPortfolio.length > 0">
      <template v-if="windowWidth > 1111">
        <div class="home__content_left">
          <template v-for="(item, index) in filteredPortfolio">
            <PreviewCard
              class="home__card"
              :key="item.id"
              :card="item"
              v-if="(index + 1) % 2"
              :index-card="index"
            />
          </template>
        </div>
        <div class="home__content_right">
          <template v-for="(item, index) in filteredPortfolio">
            <PreviewCard
              class="home__card"
              :key="item.id"
              :card="item"
              v-if="!((index + 1) % 2)"
              :index-card="index"
            />
          </template>
        </div>
      </template>
      <template v-else>
        <PreviewCard
          v-for="item in listCards"
          class="home__card"
          :key="item.id"
          :card="item"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import Filter from "@/components/Filter.vue";
import PreviewCard from "@/components/PreviewCard.vue";
import axios from "axios";
import { useI18n } from "vue3-i18n";
const baseUrl =
  process.env.NODE_ENV === "development" ? "https://viento.ru/api" : "/api";

export default {
  name: "Home",
  components: {
    Filter,
    PreviewCard,
  },
  setup(props, { emit }) {
    const title = ref("Все работы");
    const activeFilter = ref(null);

    const selectFilter = (value) => {
      activeFilter.value = 0;
      setTimeout(() => {
        activeFilter.value = value || 0;
      }, 100);
    };

    const listCards = ref([]);

    const filteredPortfolio = computed(() => {
      if (activeFilter.value === 0) return [];
      if (
        activeFilter.value?.label === "Все работы" ||
        activeFilter.value?.label === "All works"
      )
        return listCards.value;
      if (listCards.value.length === 0) return [];
      return listCards.value.filter((item) => {
        return item.category_id === activeFilter.value.value;
      });
    });

    const i18n = useI18n();

    const lang = computed(() => {
      return i18n.getLocale();
    });

    watch(lang, async () => {
      filters.value = [];
      listCards.value = [];
      await fetchCategories();
      fetchListPorfolio();
    });

    const filters = ref([]);
    const fetchCategories = async () => {
      const lang = i18n.getLocale();
      try {
        const {
          data: { category },
        } = await axios.get(`${baseUrl}/category`, {
          params: { lang },
        });

        filters.value = category.map(({ name, id }) => {
          return { label: name, value: id };
        });
        // const category = [
        //   {
        //     name: "Все работы",
        //     id: "all",
        //     video: require("@/assets/video/branding.mp4"),
        //   },
        //   {
        //     name: "Брендинг",
        //     id: "branding",
        //     video: require("@/assets/video/mobile.mp4"),
        //   },
        //   { name: "Web", id: "web" },
        //   { name: "Mobile", id: "mobile" },
        // ];
        filters.value = category.map(({ name, id, video }) => {
          return { label: name, value: id, video };
        });
        emit("loadCategory", filters.value);
      } catch {
        console.log("error");
        const category = [
          { name: "Все работы", id: "all" },
          { name: "Брендинг", id: "branding" },
          { name: "Web", id: "web" },
          { name: "Mobile", id: "mobile" },
        ];
        filters.value = category.map(({ name, id }) => {
          return { label: name, value: id };
        });
      }
    };

    fetchCategories();

    const fetchListPorfolio = async () => {
      const lang = i18n.getLocale();

      try {
        const {
          data: { items },
        } = await axios.get(`${baseUrl}/portfolio`, {
          params: { lang },
          // type: activeFilter.value
        });
        listCards.value = items;
      } catch {
        console.log("error");
      }
      // setTimeout(() => {
      //   listCards.value = [
      //     {
      //       title: "Performa1",
      //       desc: "Web Site",
      //       previews: [
      //         require("@/assets/preview.png"),
      //         "https://picsum.photos/200/300",
      //         "https://picsum.photos/200/300",
      //         "https://picsum.photos/200/300",
      //       ],
      //       category_id: "all",
      //     },
      //     {
      //       title: "JW.S2",
      //       description:
      //         "2021 Site Redesign2021 Site Redesign2021 Site Redesign2021 Site Redesign2021 Site Redesign2021",
      //       previews: [
      //         require("@/assets/preview.png"),
      //         require("@/assets/preview.png"),
      //       ],
      //       category_id: "all",
      //     },
      //     {
      //       title: "Performa3",
      //       description: "Web Site",
      //       previews: [
      //         require("@/assets/preview.png"),
      //         require("@/assets/preview.png"),
      //       ],
      //       category_id: "all",
      //     },
      //     {
      //       title: "JW.S4",
      //       description: "2021 Site Redesign",
      //       previews: [
      //         require("@/assets/preview.png"),
      //         require("@/assets/preview.png"),
      //       ],
      //       category_id: "all",
      //     },
      //   ];
      // }, 0);
    };

    fetchListPorfolio();

    const windowWidth = ref(0);

    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    const hoverItemFilter = (item) => {
      emit("hoverItemFilter", item);
    };

    const leaveItemFilter = (item) => {
      emit("leaveItemFilter", item);
    };
    return {
      title,
      activeFilter,
      selectFilter,
      listCards,
      windowWidth,
      hoverItemFilter,
      leaveItemFilter,
      filters,
      filteredPortfolio,
      lang,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  &__filter {
    margin-bottom: 32px;

    @include at("m") {
      margin-bottom: 48px;
    }

    @include at("hd") {
      margin-bottom: 162px;
    }
  }

  &__content {
    // padding-bottom: 110px;
    overflow: hidden;
    box-sizing: border-box;
    min-width: 0;

    @include at("s") {
      margin-right: -24px;
    }

    @include at("m") {
      margin-right: -40px;
    }

    @include at("l") {
      padding-bottom: 110px;
      margin-right: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 32px;
      max-width: 1600px;
    }

    &_right {
      position: relative;
      top: 110px;
      grid-column: 2/3;
      width: 100%;
      overflow: hidden;
    }
    &_left {
      grid-column: 1/2;
      width: 100%;
      overflow: hidden;
    }
  }

  &__card {
    margin-right: -40px;
    margin-bottom: 32px;

    @include at("m") {
      margin-bottom: 48px;
    }

    @include at("l") {
      margin-right: 0;
      margin-bottom: 80px;
    }
  }
}
</style>

<template>
  <div
    :class="['header', { 'header_in-footer': headerInFooter }]"
    ref="header"
    v-click-outside="closeModal"
  >
    <Logo />
    <div class="header__menu">
      <div class="lang">
        <div class="lang__button" @click="isShowModalLang = !isShowModalLang">
          <div>{{ lang }}</div>
          <Icon type="caret" />
        </div>
        <div class="lang__options" v-if="isShowModalLang">
          <div v-if="locale === 'en'" @click="setLocale('ru')">ru</div>
          <div v-if="locale === 'ru'" @click="setLocale('en')">en</div>
        </div>
      </div>
      <Button
        :type="headerInFooter ? 'transparent' : ''"
        :class="[
          'header__button',
          { header__button_shortcut: windowWidth > 0 },
        ]"
        icon="message"
        :text="$t('buttonHeaderName')"
        @click="openForm"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import Logo from "@/components/Logo/Logo.vue";
import Button from "@/components/Uikit/Button.vue";
import Icon from "@/components/Uikit/Icon/Icon.vue";
import { useI18n } from "vue3-i18n";

export default {
  name: "Header",
  components: {
    Logo,
    Button,
    Icon,
  },
  props: {},
  setup() {
    const windowWidth = ref("");

    const updateTop = () => {
      windowWidth.value = window.scrollY;
    };

    const lang = computed(() => {
      return i18n.getLocale();
    });

    const isShowModalLang = ref(false);

    updateTop();

    window.addEventListener("scroll", updateTop);

    const i18n = useI18n();

    const setLocale = (lang) => {
      i18n.setLocale(lang);
      isShowModalLang.value = false;
    };

    const locale = computed(() => {
      return i18n.getLocale();
    });

    const header = ref();

    const headerInFooter = ref(false);

    const headerListener = () => {
      const footerRect = document
        .querySelector(".footer")
        .getBoundingClientRect();
      const headerRect = header.value.getBoundingClientRect();

      if (footerRect.top < headerRect.height - 50) {
        headerInFooter.value = true;
      } else {
        headerInFooter.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener("scroll", headerListener);
    });

    const closeModal = () => {
      isShowModalLang.value = false;
    };

    const openForm = () => {
      const footerRect = document
        .querySelector(".footer")
        .getBoundingClientRect();
      document.documentElement.scrollTop =
        document.documentElement.scrollHeight - footerRect.height - 140;
    };

    return {
      windowWidth,
      setLocale,
      locale,
      lang,
      isShowModalLang,
      closeModal,
      openForm,
      header,
      headerInFooter,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 20px 24px;
  background-color: white;
  transition: all 0.3s;

  @include at("m") {
    padding: 24px 40px;
    background-color: white;
  }

  @include at("l") {
    padding: 24px 64px;
    background-color: transparent;
  }

  ::v-deep &__button {
    transition: all 0.3s;

    @include below("m") {
      width: 16px;
    }

    span {
      display: block;
      overflow: hidden;
      width: 96px;
      transition: all 0.3s;
    }

    @include below("m") {
      span {
        display: block;
        overflow: hidden;
        width: 0;
        transition: all 0.3s;
      }

      .button__icon {
        margin-left: 0px;
      }
    }

    &_shortcut {
      span {
        display: block;
        overflow: hidden;
        width: 0;
        transition: all 0.3s;
      }

      .button__icon {
        margin-left: 0px;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    z-index: 3;
  }

  &_in-footer {
    // background: #131313;
    transition: background 0.3s;
  }
}

.lang {
  position: relative;
  margin-right: 25px;
  transition: all 0.3s;

  .header_in-footer & {
    color: white;
    transition: all 0.3s;

    ::v-deep path {
      stroke: white;
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    transition: color 0.2s;

    &:hover {
      color: #f15a22;
      transition: color 0.2s;
    }

    @include at("m") {
      font-size: 20px;
      line-height: 24px;
    }

    div {
      cursor: pointer;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;

    div {
      margin-right: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;

      @include at("m") {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
</style>
